.title {
  position: relative;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 15px  !important;
  letter-spacing: 1px !important;
}

.title::before {
  position: absolute;
  content: '';
  background: #fff;
  width: 60%;
  height: 1px;
  top: 25px;
  left: -12px;
  animation: lineGrowTop 1.3s ease-in;
}

.title::after {
  position: absolute;
  content: '';
  background: #fff;
  width: 1px;
  height: 10vh;
  top: 25px;
  left: -12px;
  animation: lineGrowBottom 1.3s ease-in;
}

@keyframes lineGrowTop {
  0% {
    width: 0;
  }

  100% {
    width: 60%;
  }
}

@keyframes lineGrowBottom {
  0% {
    height: 0;
  }

  100% {
    height: 10vh;
  }
}
